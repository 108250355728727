import { type RichTextField } from "@prismicio/types";
import { type UiElement } from "~/core/types";
import { cn } from "~/core/styles/helpers";
import { RichText } from "~/coreUI/Content/RichText/RichText";

interface TextBlockProps extends UiElement {
  title: RichTextField;
  paragraph: RichTextField;
}

export const TextBlock = ({ title, paragraph, className }: TextBlockProps) => {
  return (
    <div
      className={cn(
        " flex w-full max-w-[30rem] flex-col items-start justify-start gap-11 px-8 py-0",
        className
      )}
    >
      <div className="relative self-stretch text-[36px] font-black">
        <RichText field={title} />
      </div>
      <div className="relative self-stretch text-[1rem] font-light">
        <RichText field={paragraph} />
      </div>
    </div>
  );
};
