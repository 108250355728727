import { cn } from "~/core/styles/helpers";
import { Container } from "~/coreUI/Layout/Container/Container";
import {
  type TextCardSliceText,
  type TextCardSliceImage,
} from "prismicio-types";
import { Button, type ButtonProps } from "~/coreUI/Basic/Button/Button";
import { RichText } from "~/coreUI/Content/RichText/RichText";
import { baseStyle, bgStyles, shadowStyles } from "~/core/styles/cvaStyles";
import { cva } from "class-variance-authority";
import { Image } from "~/coreUI/Content/Image/Image";
import { motion } from "framer-motion";
import { animationBaseProps, variants } from "~/core/styles/animations";
import { TextBlock } from "~/core/components/Content/Slices/TextBlock/TextBlock";

interface TextCardProps {
  slice: TextCardSliceImage | TextCardSliceText;
  children: React.ReactNode;
}

const card = cva("", {
  variants: {
    bgColor: {
      default: "bg-default-soft dark:shadow-[#22262c] shadow-[#c2c2c2]",
      soft: "bg-default ",
    },
  },
});

export const TextCard = ({ slice, children }: TextCardProps) => {
  const { title, paragraph, color, bgColor = "default" } = slice.primary;

  return (
    <Container
      className={cn(
        color,
        "w-full bg-cover bg-center bg-no-repeat",
        bgStyles({ bgColor })
      )}
      innerClassName="flex flex-col md:grid md:grid-cols-2 gap-24 md:gap-12 items-center"
      yPadding="lg"
    >
      <TextBlock paragraph={paragraph} title={title} className="flex-1" />
      <div className=" flex w-full flex-col items-center justify-center self-stretch py-3 md:w-auto md:px-8">
        <div className="flex w-full justify-center">
          <motion.div
            {...animationBaseProps}
            variants={variants.fromRight}
            className={cn(
              "flex w-full max-w-sm shrink-0 flex-row items-center justify-center rounded-xl p-12 text-[1.25rem] ",
              baseStyle({ hasShadow: true }),
              shadowStyles({ size: "lg" }),
              card({ bgColor })
            )}
          >
            {children}
          </motion.div>
        </div>
      </div>
    </Container>
  );
};

export const TextCardContent = (slice: TextCardSliceText) => {
  const { cardtext, cardbuttonlabel, cardtitle, cardbuttonlink, color } =
    slice.primary;
  return (
    <TextCard slice={slice}>
      <div className={cn("flex w-full flex-col items-start gap-4", color)}>
        {cardtitle && (
          <div className="  relative text-md font-black leading-relaxed">
            <RichText field={cardtitle} hasAnimation={false} />
          </div>
        )}
        {cardtext && (
          <div className="text-xs">
            <RichText field={cardtext} hasAnimation={false} />
          </div>
        )}

        <Button
          className="mt-8"
          color={color as ButtonProps["color"]}
          shapeStyle="outline"
          label={cardbuttonlabel}
          size="md"
          field={cardbuttonlink}
          as="prismicLink"
          bgColor="soft"
        />
      </div>
    </TextCard>
  );
};

export const TextCardImage = (slice: TextCardSliceImage) => {
  const { imagedark, imagelight } = slice.primary;
  return (
    <TextCard slice={slice}>
      <div className="relative ">
        <Image
          image={imagedark}
          alt={imagedark.alt ?? ""}
          className="mx-auto dark:hidden"
        />
        <Image
          image={imagelight}
          alt={imagelight.alt ?? ""}
          className="mx-auto hidden dark:block"
        />
      </div>
    </TextCard>
  );
};
