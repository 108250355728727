import { type Content } from "@prismicio/client";
import { type SliceComponentProps } from "@prismicio/react";

import {
  TextCardContent,
  TextCardImage,
} from "~/coreUI/Content/Slices/TextCard/TextCard";

export type TextCardProps = SliceComponentProps<Content.TextCardSlice>;

const TextCard = ({ slice }: TextCardProps): JSX.Element => {
  switch (slice.variation) {
    case "text":
      return <TextCardContent {...slice} />;

    case "image":
      return <TextCardImage {...slice} />;
  }
};

export default TextCard;
